import * as React from "react";
import Column from "./Column";
import Grid from "./Grid";
import Gutter from "./Gutter";
import MaxWidth from "./MaxWidth";
import Button from "./Button";
import HorizontalAlign from "./HorizontalAlign";
import Section from "./Section";
import Text from "./Text";
import Line from "./Line";
import PageMaxWidth from "./PageMaxWidth";
import LOGO_LIGHT from "../../img/ui/logo_light.svg";
import SOCIAL_LINKEDIN from "../../img/ui/footer/social_linkedin.svg";
import SOCIAL_TWITTER from "../../img/ui/footer/social_twitter.svg";
import { Link } from "gatsby";
import styled from "styled-components";
import { Color, Dimen } from "../constants";
import Visibility from "./Visibility";
import NEWSLETTER_ICON from "../../img/ui/footer/newsletter_icon.svg";
import STRIPED_BACKGROUND from "../../img/ui/footer/striped_background.svg";
import STRIPED_CURVE from "../../img/ui/footer/striped_curve.svg";
import Spacer from "./Gutter";
import { NAV_SUB_MENUS } from "../nav";

type Props = {
  backColor?: string;
};

export default function LayoutFooter({ backColor = Color.light1 }: Props) {
  return (
    <footer style={{ backgroundColor: backColor }}>
      <StyledStripedCurve />
      <Section background="dark" tint="light">
        <StyledStripedBackground>
          <PageMaxWidth>
            <Gutter />
            <MaxWidth width={540}>
              <Text typeface="h2" bold as="p" align="center">
                Turn your team into champions of automation
              </Text>
            </MaxWidth>
            <Gutter size={1.25} />
            <MaxWidth width={900}>
              <Text typeface="h5" as="p" align="center">
                Start your data entry automation journey now: go live with
                Shipamax in under in 6 weeks with{" "}
                <Text typeface="h5" color="orange1">
                  95% automation
                </Text>{" "}
                and{" "}
                <Text typeface="h5" color="orange1">
                  85% time savings
                </Text>{" "}
                from day one.
              </Text>
            </MaxWidth>
            <Gutter size={2} />
            <Grid>
              <Column size={6}>
                <HorizontalAlign align="flex-end" md="center">
                  <Button
                    colorTheme="orange"
                    label="Get in touch"
                    to="/contact"
                  />
                </HorizontalAlign>
              </Column>
              <Column size={6}>
                <HorizontalAlign align="flex-start" md="center">
                  <Button
                    colorTheme="hollow-white"
                    label="Why Shipamax"
                    to="/why-shipamax"
                  />
                </HorizontalAlign>
              </Column>
            </Grid>
            <Gutter size={4} md={2} />


            <Line color="light1" lowOpacity />
            <Gutter size={4} md={2} />

            <Grid>
              <Column size={5}>
                <img src={LOGO_LIGHT} alt="" width="160" />
              </Column>
              <Column size={7}>
                {/* <Visibility hideBelow="md">
                  <Text typeface="h5" bold>
                    Turn your team into champions of automation
                  </Text>
                    </Visibility> */}
              </Column>
            </Grid>
            <Gutter size={2.5} md={0} />

            <nav>
              <Grid>
                <Column size={5}>
                  <StyledFooterContacts>
                    <div>
                      <Text as="p" typeface="caption">
                        <a href="mailto:sales@shipamax.com">
                          sales@shipamax.com
                        </a>
                      </Text>
                      <Gutter md={0} />

                      <Text as="p" typeface="caption" color="light7">
                        2nd Floor <br />
                        241 Southwark Bridge Road
                        <br />
                        London
                        <br />
                        SE1 6FP
                        <br />
                        UK{" "}
                      </Text>
                      <Gutter size={2} />
                    </div>
                    <div>
                      <StyledSocials>
                        <a
                          href="https://www.linkedin.com/company/shipamax/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={SOCIAL_LINKEDIN} alt="LinkedIn" />
                        </a>
                        <Gutter size={0.75} />
                        <a
                          href="https://twitter.com/shipamax?lang=en-gb"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={SOCIAL_TWITTER} alt="Twitter" />
                        </a>
                      </StyledSocials>
                    </div>
                  </StyledFooterContacts>
                </Column>
                <Column size={4}>
                  {/* <Visibility hideAbove="md">
                    <Line color="light1" lowOpacity />
                    <Gutter size={1.5} />
                    <Text typeface="h5" bold>
                      Turn your team into champions of automation
                    </Text>
                    <Gutter size={1.5} />
                    </Visibility> */}

                  <Text as="p" typeface="caption" bold>
                    Why Shipamax
                  </Text>
                  <StyledLinksColumn>
                    {NAV_SUB_MENUS.whyShipamax.map((item, i) => (
                      <StyledLink key={i} to={item.linkTo}>
                        <Text typeface="caption">{item.title}</Text>
                      </StyledLink>
                    ))}
                    <StyledLink to="/shipamax-vs-traditional-ocr">
                      <Text typeface="caption">Shipamax vs OCR</Text>
                    </StyledLink>
                  </StyledLinksColumn>
                  <Gutter size={1.5} />

                  <Text as="p" typeface="caption" bold>
                    Shipamax For
                  </Text>
                  <StyledLinksColumn>
                    {NAV_SUB_MENUS.useCases.map((item, i) => (
                      <StyledLink key={i} to={item.linkTo}>
                        <Text typeface="caption">{item.title}</Text>
                      </StyledLink>
                    ))}
                  </StyledLinksColumn>
                </Column>

                <Column size={3}>
                  <Text as="p" typeface="caption" bold>
                    Solutions
                  </Text>
                  <StyledLinksColumn>
                    {NAV_SUB_MENUS.solutions.map((item, i) => (
                      <StyledLink key={i} to={item.linkTo}>
                        <Text typeface="caption">{item.title}</Text>
                      </StyledLink>
                    ))}
                  </StyledLinksColumn>
                  <Gutter size={1.5} />

                  <Text as="p" typeface="caption" bold>
                    Resources
                  </Text>
                  <StyledLinksColumn>
                    {NAV_SUB_MENUS.resources.map((item, i) => (
                      <StyledLink key={i} to={item.linkTo}>
                        <Text typeface="caption">{item.title}</Text>
                      </StyledLink>
                    ))}
                    <StyledLink to="/logistics-document-processing">
                      <Text typeface="caption">
                        Logistics Document Processing
                      </Text>
                    </StyledLink>
                  </StyledLinksColumn>
                  <Gutter size={1.5} />

                  <Text as="p" typeface="caption" bold>
                    Company
                  </Text>
                  <StyledLinksColumn>
                    {NAV_SUB_MENUS.company.map((item, i) => (
                      <StyledLink key={i} to={item.linkTo}>
                        <Text typeface="caption">{item.title}</Text>
                      </StyledLink>
                    ))}
                    <StyledLink to="/contact">
                      <Text typeface="caption">Contact Us</Text>
                    </StyledLink>
                  </StyledLinksColumn>
                </Column>
              </Grid>
            </nav>
            <Gutter size={2.5} />

            <Visibility hideAbove="md">
              <Line color="light1" lowOpacity />
              <Gutter />
            </Visibility>
            <Grid>
              <Column size={8}>
                <Text typeface="caption" color="light7">
                  © {new Date().getFullYear()} Shipamax Ltd. - WISETECH GLOBAL GROUP
                </Text>
              </Column>
              <Column size={2}>
                <StyledLink to="/privacy">
                  <Text typeface="caption">Privacy Policy</Text>
                </StyledLink>
              </Column>
              <Column size={2}>
                <StyledLink to="/terms">
                  <Text typeface="caption">Terms and Conditions</Text>
                </StyledLink>
              </Column>
              {/* <Column size={3}>
                <StyledLink to="/sitemap.xml">
                  <Text typeface="caption">Sitemap</Text>
                </StyledLink>
              </Column> */}
            </Grid>
            <Gutter size={2} />
          </PageMaxWidth>
        </StyledStripedBackground>
        <noscript>
          <img src="https://ws.zoominfo.com/pixel/61e93cbc342d59001e8e6f65" width="1" height="1" style={{ display: 'none' }} />
        </noscript>
      </Section>
    </footer>
  );
}

const StyledStripedCurve = styled.div`
  background-image: url(${STRIPED_CURVE});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  height: 210px;

  /* Avoid white line appearing at some zoom levels */
  position: relative;
  top: 1px;

  @media (max-width: 1940px) {
    height: 125px;
  }

  @media (max-width: 1680px) {
    height: 88px;
  }

  @media (max-width: ${Dimen.breakpointDownXl}px) {
    height: 68px;
  }
`;

const StyledStripedBackground = styled.div`
  background-image: url(${STRIPED_BACKGROUND});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  background-color: #0a1118;
`;

const StyledLinksColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > a {
    display: block;
    margin-top: 8px;
    break-inside: avoid;

    &:first-child {
      margin-top: 10px;
    }
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    columns: 2;
  }
`;

const StyledLink = styled(Link)`
  color: ${Color.light7};
  transition: color 0.25s;

  &:hover {
    color: ${Color.light6};
  }
`;

const StyledNewsletterForm = styled.form`
  display: flex;
  align-items: stretch;
  height: 64px;
  margin: 0;
  position: relative;

  > input {
    flex: 1;
    max-width: 300px;
    padding-left: 24px;
    background-color: transparent;
    border: 2px ${Color.light1} solid;
    border-right-width: 0;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    color: ${Color.light1};

    &::placeholder {
      color: ${Color.light1};
    }
  }

  > label {
    position: absolute;
    top: 17px;
    left: 30px;
    display: flex;
    align-items: center;
    pointer-events: none;

    img {
      display: block;
      padding-top: 3px;
    }
  }

  > button {
    background-color: ${Color.light1};
    border: 0;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    padding-left: ${Dimen.gutterWidth}px;
    padding-right: ${Dimen.gutterWidth * 1.5}px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 200ms;

    &:hover {
      background-color: ${Color.light4};
    }

    @media (max-width: ${Dimen.breakpointDownMd}px) {
      padding-left: ${Dimen.gutterWidth / 2}px;
      padding-right: ${Dimen.gutterWidth / 2}px;
    }
  }
`;

const StyledFooterContacts = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    width: 50%;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: ${Dimen.breakpointDownMd}px) {
      align-items: flex-end;
    }
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const StyledSocials = styled.div`
  display: flex;

  a {
    transition: opacity 200ms;
    &:hover {
      opacity: 0.8;
    }
  }

  img {
    width: 32px;
    height: 32px;
  }
`;
